import React, {useEffect, useState} from 'react'
import Countdown from "react-countdown";
import Confirm from '../Confirm/Confirm'
import atob from 'atob'
import moment from 'moment'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import InputMask from 'react-input-mask'
import MainBlock from '../utils/MainBlock'
import {Backdrop, CircularProgress} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import {API} from '../../../api'
import {tgAPI} from '../../../api/indextg'
import key from '../../../img/key.svg'
import img from '../../../img/logo-small.svg'
import telegram from '../../../img/telegram.svg'
import dds from '../../../img/dds.svg'
import uzcard from '../../../img/uzcard.svg'
import humo from '../../../img/humo.svg'
import masterCard from '../../../img/mastercard.svg'
import visa from '../../../img/visa.svg'
import cvTip from '../../../img/cvTip.svg'
import addCard from '../../../img/addCard.svg'
import keymerch from '../../../img/keymerch.svg'
import logoGB from '../../../img/logo-global.svg'
import {Tooltip} from 'antd';
import tooltip from '../../../img/tooltip.svg'
import tooltipDanger from '../../../img/tooltip-danger.svg'
import {decode, encode} from 'js-base64';
import {isJsonString} from "../utils/json-parses";


import {
    Column,
    CompanyImage,
    CompanyName,
    CustomInput,
    ModalInfo,
    PayBtn,
    PayCard,
    PaymentDetails,
    TextDetail
} from './styles.js';
import axios from "axios";

const myTimer = Date.now() + 59900 * 15;

const renderer = ({minutes, seconds, completed}) => {
    if (completed) {
        document.querySelector("ant-modal-body").style.height = window.innerHeight + 'px'
        window.location.href = 'https://pay.global.uz/'
        alert('Время для оплаты истекло')
    } else {
        // Render a countdown
        return (
            <span>
        {minutes}:{seconds}
      </span>
        );
    }
}


const HeaderModal = () => {
    return (
        <div>
            <div className='logo-modal'>
                <img src={logoGB}/>
            </div>
        </div>
    )
}

const CloseIconDesign = () => {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 1L1 13M1 1L13 13" stroke="#667085" strokeWidth="2" strokeLinecap="round"
                  strokeLinejoin="round"/>
        </svg>

    )
}

const Main = () => {
    const {t} = useTranslation()
    const history = useHistory();

    const [tg, setTg] = useState(false)
    const [tgId, setTgId] = useState('')
    const [hash, setHash] = useState('')
    const [secureHash, setSecureHash] = useState({
        id: '',
        trans_id: ''
    })
    const [merchCode, setMerchCode] = useState('')

    let params = new URLSearchParams(window.location.search);
    const [errorNumber, setErrorNumber] = useState(false);
    const [errorExpire, setErrorExpire] = useState(false);
    const [errorCvc, setErrorCvc] = useState(false)
    const [errorCardholderName, setErrorCardholderName] = useState(false)
    const [errorCardholderNameMessage, setErrorCardholderNameMessage] = useState(false)
    const [errorCvcMessage, setErrorCvcMessage] = useState('')
    const [errorMessage, setErrorMessage] = useState('');
    const [errorMessageDate, setErrorMessageDate] = useState('');
    const [loader, setLoader] = useState(false);
    const [alert, setAlert] = useState(false);
    const [alertDate, setAlertDate] = useState(false);
    const [confirm, setConfirm] = useState(true);
    const [ipAddress, setIpAddress] = useState('')
    const [imageLogo, setImageLogo] = useState('')
    const [phone, setPhone] = useState('');
    const [card, setCard] = useState({
        number: '',
        expire: '',
        cvc: '',
        cardholder_name: '',
        client_ip_address: ''
    });


    const [modalOpen, setModalOpen] = useState(false)
    const [data, setData] = useState({
        providerId: null,
        serviceId: null,
        accountId: '',
        amount: null
    });
    const [id, setId] = useState('');
    const [detail, setDetail] = useState({});

    const [success, setSuccess] = useState(false);

    const [url, setUrl] = useState('')
    const setSuccessP = (value) => {
        setSuccess(value)
    }
    const goBack = () => {
        setConfirm(false)
    }


    useEffect(() => {
        if (params.get('vm') || params.get('cvc')) {
            const getIp = () => {
                return axios.get('https://geolocation-db.com/json/')
            }
            getIp().then((res) => {
                setIpAddress(res.data.IPv4)
            })
        }
        const getData = () => {
            if (window.location.search) {
                setLoader(true);
                let params = new URLSearchParams(window.location.search);
                if (params.get('vm') || params.get('cvc')) {
                    if (params.get('tgbot')) {
                        setTg(params.get('tgbot'))
                        setLoader(false)
                    }
                    if (params.get('hash')) {
                        setHash(params.get('hash'))
                        setLoader(false)
                    }
                } else {
                    if (params.get('tgbot') && params.get('hash')) {
                        history.push('/not-found')
                    } else if (params.get('hash')) {
                        setHash(params.get('hash'))
                        setLoader(false)
                    } else if (params.get('tgbot')) {
                        setTg(params.get('tgbot'))
                        setLoader(false)
                        // setBody(params.get('body'));
                        // setLang(params.get('lang'));
                    } else {
                        setLoader(false)
                        if (!params.get('cvc')) {
                            let bin = atob(params.get('body'));
                            let lang = params.get('lang');
                            setUrl(`${params.get('url')}` || 'https://pay.global.uz/')
                            let data = new URLSearchParams(bin);
                            localStorage.setItem("language", lang)
                            setData({
                                providerId: data.get('provider_id'),
                                serviceId: data.get('service_id'),
                                accountId: data.get('account_id'),
                                amount: data.get('amount'),
                                time: data.get('time'),
                                order_id: data.get('order_id')
                            });
                            API.tData({
                                provider_id: parseInt(data.get('provider_id')),
                                service_id: parseInt(data.get('service_id')),
                                account_id: data.get('account_id'),
                                amount: parseInt(data.get('amount')) * 100,
                                time: parseInt(data.get('time')) || moment().unix(new Date()) * 1000,
                                order_id: data.get('order_id') || null
                            })
                                .then(({data}) => {
                                    setId(data['transaction_id'])
                                    setDetail(data)
                                    setImageLogo(data.provider_icon_id)
                                })
                                .catch((e) => {
                                    history.push({
                                        pathname: '/error'
                                    })
                                })
                                .finally(() => setLoader(false))
                        }
                    }
                }
            } else {
                history.push('/not-found')
            }
        }
        getData()
    }, [history, t])

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setAlert(false);
    };

    const sendData = () => {
        if (params.get('cvc')) {
            let decodedHash = hash ? decode(hash) : ''
            const parsedHash = isJsonString(decodedHash) ? JSON.parse(decodedHash) : null
            if (parsedHash) {
                setLoader(true)
                setErrorMessage('')
                setAlert(false)
                const data = {
                    card_token: parsedHash.cardToken,
                    cvc2: card.cvc,
                    cardholder_name: card.cardholder_name,
                    client_ip_address: ipAddress,
                    createPaymentData: parsedHash.createPaymentData,
                    nextPageData: parsedHash.nextPageData
                }
                tgAPI.sendCvc(data)
                    .then((response) => {
                        if (response && response.data) {
                            if (response.data.result && response.data.result.hash) {
                                const nextPageData = JSON.parse(decode(response.data.result.hash)).nextPageData
                                let decodedHash = decode(encode(nextPageData))
                                const parsedHash = isJsonString(decode(decodedHash)) ? JSON.parse(decode(decodedHash)) : undefined
                                if (parsedHash && parsedHash.transId) {
                                    window.location.replace(`https://ecomm.kapital24.uz:2443/ecomm2/ThreeDs2Handler/ProcessingPage?trans_id=${parsedHash.transId}`)
                                } else {
                                    window.location.replace = ('https://global.uz/404')
                                }
                            }
                        }
                    }).catch((e) => {
                    setErrorMessage(e.response?.status === 422 ? `${t('Error')}` : e.response?.data ? e.response?.data?.error?.body?.message : `${t('Error')}`)
                    setAlert(true)
                })
                    .finally(() => setLoader(false))
            } else {
                setErrorMessage('Некорректные данные')
                setAlert(true)
            }
        } else {
            if (card.number.length === 19 && card.expire.length === 5) {
                if (tg) {
                    const data = {
                        card_number: card.number.split(' ').join(''),
                        exp_date: `${card['expire'].split('/')[0]}/${card['expire'].split('/')[1]}`,
                    }
                    if (params.get('vm') && card.number.match(/^5+/) && !card.number.match(/^5614|5440/) || card.number.match(/^4+/)) {
                        data['cvc2'] = card.cvc
                        data['cardholder_name'] = card.cardholder_name
                        data['client_ip_address'] = ipAddress
                    }
                    setLoader(true);
                    tgAPI.sendCard(data)
                        .then(({data, status}) => {
                            if (status === 200) {
                                if (params.get('vm')) {
                                    if (data.result.hash) {
                                        if (card.number.match(/^5+/) && !card.number.match(/^5614|5440/) || card.number.match(/^4+/)) {
                                            let decoded = decode(data.result.hash)
                                            let parsedHash = isJsonString(decoded) ? JSON.parse(decoded) : undefined
                                            if (parsedHash) {
                                                window.TelegramGameProxy.paymentFormSubmit({
                                                    credentials: {type: "card", token: parsedHash.cardToken},
                                                    title: parsedHash.cardTitle
                                                })
                                                setCard({
                                                    number: '',
                                                    cvc: '',
                                                    expire: '',
                                                    cardholder_name: "",
                                                    client_ip_address: ''
                                                })
                                            }
                                        } else {
                                            setTgId(data.result.hash)
                                            setConfirm(true);
                                        }
                                    }

                                } else {
                                    setTgId(data.result.hash)
                                    setConfirm(true);
                                }
                            }
                        })
                        .catch((e) => {
                            setErrorMessage(e.response?.status === 422 ? `${t('Error')}` : e.response?.data ? e.response?.data?.error?.body?.message : `${t('Error')}`)
                            setAlert(true)
                        })
                        .finally(() => setLoader(false))
                } else {
                    setLoader(true);
                    API.tCard(id, {
                        number: card['number'].split(' ').join(''),
                        expiry_date: `${card['expire'].split('/')[1] + card['expire'].split('/')[0]}`
                    })
                        .then(({status, data}) => {
                            if (status === 202) {
                                setPhone(data['telephone_number'])
                                setConfirm(true);
                            }
                        })
                        .catch((e) => {
                            setErrorMessage(e.response.data.data ? e.response.data.data[`${localStorage.getItem('language')}`] : `${t('Error')}`)
                            setAlert(true)
                        })
                        .finally(() => setLoader(false))
                }
            }
            if (card.number.length !== 19) {
                setErrorNumber(true)
            }
            if (card.expire.length !== 5) {
                setErrorExpire(true)
            }
        }
    }
    const sendDataMerch = () => {

        setLoader(true);
        tgAPI.merch(hash, merchCode)
            .then(({status}) => {
                setLoader(false);
                if (status === 200) {
                    setSuccessP(true);
                    setConfirm(true);
                }

            })
            .catch(e => {
                setLoader(false);
                setMerchCode('');
                //setErrorMessage(e.response?.status === 422 ? `${t('Error')}` : e.response?.data ? e.response.data?.error.body.message : `${t('Error')}`)
                setErrorMessage(e.response?.data?.error?.body?.message ? e.response.data?.error.body.message : `${t('Error')}`)
                setAlert(true);
            })
    }


    function validationCardInput(value) {
        if (params.get('vm')) {
            if (value.length >= 4 && !value.match(/(8600|5614|5440|6264|6262|6263|9860|^4|^5)+/)) {
                setErrorNumber(true)
                setErrorMessage('Недействительный номер карты')
            } else {
                setErrorNumber(false)
                setAlert(false)
            }
            if (!value.match(/^5+/) && value.match(/^5614|5440/) || !value.match(/^4+/)) {
                setErrorCvc(false)
                setErrorCvcMessage('')
                setErrorCardholderNameMessage('')
                setErrorCardholderName(false)
                setCard({
                    ...card,
                    cardholder_name: ''
                })
            }
            setCard({
                ...card,
                number: value,
                cvc: value.match(/^5+/) && value.match(/^5614|5440/) || !value.match(/^4+/) ? '' : card.cvc
            })
        } else {
            if (value.length >= 4 && !value.match(/^5614|5440/) && value.match(/^5+/)) {
                setErrorNumber(true)
                setErrorMessage('Mastercard не поддерживается')
            } else if (value.match(/^4+/)) {
                setErrorNumber(true)
                setErrorMessage('Visa не поддерживается, введите Uzcard или Humo')
            } else if (value.length >= 4 && !value.match(/(8600|5614|5440|6264|6262|6263|9860)+/)) {
                setErrorNumber(true)
                setErrorMessage('Недействительный номер карты')
            } else if (value.length !== 19) {

            } else {
                setErrorNumber(false)
                setAlert(false)
            }
            if (alert) {
                setErrorNumber(false)
                setAlert(false)
            }

            setCard({...card, number: value})
        }
    }

    function validationExpireDate(value) {
        let date_split = value.split('/')
        const year = moment().format('YY')
        const month = moment().format('MM')
        if (date_split[0].length === 2 && (!date_split[1] || date_split[1].length < 2)) {
            if ((+date_split[0] > 0 && date_split[0] <= 12)) {
                setCard({...card, expire: value})
            }


        } else if (date_split[0].length === 2 && date_split[1].length === 2) {
            if (+date_split[1] >= year) {
                setCard({...card, expire: value})
                if (date_split[1] === year && +date_split[0] < month) {
                    setErrorExpire(true)
                    setErrorMessageDate('Срок действия карты истек или указан не верно')
                } else {
                    setErrorExpire(false)
                    setErrorMessageDate('')
                }
            }
        } else {
            setCard({...card, expire: value})
        }
        if (alert) {
            setErrorExpire(false)
            setAlert(false)
        }
    }


    function validationCvv(value) {
        if (value.trim().length) {
            setErrorCvc(false)
            setErrorCvcMessage('')
            setCard({
                ...card,
                cvc: value
            })
        }
        if (!value.trim().length) {
            setCard({
                ...card,
                cvc: ''
            })
            setErrorCvc(true)
            setErrorCvcMessage('Это поле является обязательным')
        }
        if (value.trim().length > 0 && value.trim().length < 3) {
            setErrorCvc(true)
            setErrorCvcMessage('Недостаточно символов')
        }
        if (alert) {
            setErrorExpire(false)
            setAlert(false)
        }
    }

    function cardHolderNameValidation(value) {
        if (value.trim().length) {
            setErrorCardholderName(false)
            setErrorCardholderNameMessage('')
            setCard({
                ...card,
                cardholder_name: value
            })
        }
        if (!value.trim().length) {
            setCard({
                ...card,
                cardholder_name: ''
            })
            setErrorCardholderName(true)
            setErrorCardholderNameMessage('Это поле является обязательным')
        }
        if (value.trim().length < 3) {
            setErrorCardholderName(true)
            setErrorCardholderNameMessage('Недостаточно символов')
        }
        if (!value.trim().match(/^[a-zA-Z\s]*$/)) {
            setErrorCardholderName(true)
            setErrorCardholderNameMessage('Заполните имя на латинице')
        }
        if (alert) {
            setErrorExpire(false)
            setAlert(false)
        }
    }

    function disableBtn() {
        let isDisabled = true
        if (params.get('vm')) {
            if (card.number.match(/^5+/) && !card.number.match(/^5614|5440/) || card.number.match(/^4+/)) {
                if (card.number.length && card.expire.length && card.cvc.length && card.cardholder_name.length && !errorCvc && !errorExpire && !errorNumber && !errorCardholderName) {
                    isDisabled = false
                }
            } else {
                if (card.number.length && card.expire.length && !errorExpire && !errorNumber) {
                    isDisabled = false
                }
            }
        }
        if (!params.get('vm') && !params.get('cvc')) {
            if (card.number.length && card.expire.length && !errorExpire && !errorNumber) {
                isDisabled = false
            }
        }
        if (params.get('cvc')) {
            if (card.cvc.length && card.cardholder_name.length && !errorCvc && !errorCardholderName) {
                isDisabled = false
            }
        }
        return isDisabled
    }


    const CurrentDate = moment(new Date()).format('DD.MM.YYYY')
    const decodedHash = params.get('cvc') ? decode(encode(params.get('hash'))) : undefined
    const parsedHash = decodedHash ? isJsonString(decode(decodedHash)) ? JSON.parse(decode(decodedHash)) : '' : undefined


    if (params.get('cvc') && !parsedHash && !parsedHash.cardToken && !parsedHash.createPaymentData && !parsedHash.nextPageData) {
        window.location.href = 'https://global.uz/404'
    }

    return tg || params.get('cvc') ? (

        <MainBlock>
            <ModalInfo title={<HeaderModal/>} footer={null} open={modalOpen} onCancel={() => {
                setModalOpen(false)
                document.body.style.overflow = 'visible'
            }
            }
                       closeIcon={<CloseIconDesign/>}>
                <h3>{t('agreement.title1')}</h3>
                <p className='font-weight'>{t('agreement.title2')}</p>
                <p>{t('agreement.text1')}</p>
                <p>{t('agreement.text2')}</p>
                <p>{t('agreement.text3')}</p>
                <p>{t('agreement.text4')}</p>
                <p className='font-weight'>{t('agreement.title3')}</p>
                <p>{t('agreement.text5')}</p>
                <p>{t('agreement.text6')}</p>
                <p className='font-weight'>{t('agreement.title4')}</p>
                {
                    Array.from(Array(20).keys()).map(item => {
                        return (<p key={item}>{t(`agreement.text${item + 7}`)}</p>)
                    })
                }
                <p className='font-weight'>{t('agreement.title5')}</p>
                {
                    Array.from(Array(7).keys()).map(item => {
                        return (<p key={item}>{t(`agreement.text${item + 27}`)}</p>)
                    })
                }
                <p className='font-weight'>{t('agreement.title6')}</p>
                {
                    Array.from(Array(12).keys()).map(item => {
                        return (<p key={item}>{t(`agreement.text${item + 34}`)}</p>)
                    })
                }
                <p className='font-weight'>{t('agreement.title7')}</p>
                {
                    Array.from(Array(2).keys()).map(item => {
                        return (<p key={item}>{t(`agreement.text${item + 46}`)}</p>)
                    })
                }
                <p className='font-weight'>{t('agreement.title8')}</p>
                {
                    Array.from(Array(2).keys()).map(item => {
                        return (<p key={item}>{t(`agreement.text${item + 48}`)}</p>)
                    })
                }
                <p className='font-weight'>{t('agreement.title9')}</p>
                <p>{t(`agreement.text50`)}</p>
                <p className='font-weight'>{t('agreement.title10')}</p>
                {
                    Array.from(Array(5).keys()).map(item => {
                        return (<p key={item}>{t(`agreement.text${item + 51}`)}</p>)
                    })
                }
                <p className='font-weight'>{t('agreement.title11')}</p>
                {
                    Array.from(Array(8).keys()).map(item => {
                        return (<p key={item}>{t(`agreement.text${item + 56}`)}</p>)
                    })
                }
            </ModalInfo>
            <ModalInfo title={<HeaderModal/>} closeIcon={<CloseIconDesign/>} open={secureHash.length > 0} footer={null}
                       onCancel={() => setSecureHash({
                           id: '',
                           trans_id: ''
                       })}>
                <iframe
                    src={`https://ecomm.kapital24.uz:2443/ecomm2/ClientHandler?trans_id=qrwFbNNvAvu5v9dSeGSfvJrdUKY%3D`}/>
            </ModalInfo>
            {
                confirm ? (
                    <Column width={100} style={success ? {border: 'none', padding: 20} : {position: 'relative'}}>
                        <Confirm tgId={tgId} setSuccessP={setSuccessP} success={success} goBack={() => goBack()}
                                 dataCard={card}/>
                    </Column>
                ) : (
                    <Column width="100" style={{position: 'relative'}}>
                        <div className="main__header">
                            <div>
                                <img src={addCard} alt=""/>
                            </div>
                            <h1 className='mons-600'>Добавление карты</h1>
                        </div>
                        <PayCard>
                            {!params.get('cvc') && <h2 className="mons-500">{t('Card Number')}</h2>}

                            <form noValidate autoComplete="off" onSubmit={(e) => {
                                e.preventDefault();
                                sendData()
                            }}>
                                <div className="inner-block">
                                    {!params.get('cvc') ? <div style={{position: "relative"}}>
                                            <InputMask
                                                error={errorNumber}
                                                mask="9999 9999 9999 9999"
                                                alwaysShowMask={false}
                                                maskChar=''
                                                required
                                                margin="dense"
                                                type="tel"
                                                id="outlined-basic-number"
                                                className={`Number ${(alert || errorNumber) && 'valid-error'}`}
                                                placeholder="0000 0000 0000 0000"
                                                variant="outlined"
                                                value={card.number}
                                                style={{marginTop: 6}}
                                                onBlur={(e) => {
                                                    validationCardInput(e.target.value)
                                                    if (e.target.value.length !== 19) {
                                                        setErrorNumber(true)
                                                        setErrorMessage('Недостаточно символов')
                                                    }
                                                }}
                                                onChange={(e) => {
                                                    validationCardInput(e.target.value)
                                                }}
                                            >
                                                {(inputProps) => <CustomInput {...inputProps}/>}
                                            </InputMask>
                                            {params.get('vm') ? <div className="card-img">
                                                <div>
                                                    <img
                                                        style={{opacity: card.number.match(/8600|^5614|^5440|6264|6262|6263\s+/) ? 1 : 0.5}}
                                                        src={uzcard} alt=""/>
                                                </div>
                                                <div>
                                                    <img style={{opacity: card.number.match(/9860\s+/) ? 1 : 0.5}}
                                                         src={humo} alt=""/>
                                                </div>
                                                <div>
                                                    <img
                                                        style={{opacity: card.number.match(/^5+/) && !card.number.match(/^5614|^5440/) ? 1 : 0.5}}
                                                        src={masterCard} alt=""/>
                                                </div>
                                                <div>
                                                    <img style={{opacity: card.number.match(/^4+/) ? 1 : 0.5}} src={visa}
                                                         alt=""/>
                                                </div>
                                            </div> : <div className="card-img__absolute">
                                                <div>
                                                    <img
                                                        style={{opacity: card.number.match(/8600|5614|5440|6264|6262|6263\s+/) ? 1 : 0.5}}
                                                        src={uzcard} alt=""/>
                                                </div>
                                                <div>
                                                    <img style={{opacity: card.number.match(/9860\s+/) ? 1 : 0.5}}
                                                         src={humo} alt=""/>
                                                </div>
                                            </div>}

                                            {(alert || errorNumber) && <div className='error-mes text-left'><p
                                                style={{marginTop: 6, marginBottom: 0}}>{errorMessage}</p></div>}
                                        </div> :
                                        <div className={'accepted-card'}>
                                            <h2 className="mons-500">{t('Card Number')}</h2>
                                            <div className={'accepted-card__info'}>
                                                <img alt={''}
                                                     src={parsedHash && parsedHash.cardTitle ? parsedHash.cardTitle.split('*')[0].toLowerCase().includes('visa') ? visa : masterCard : undefined}/>
                                                {`${parsedHash && parsedHash.cardTitle ? `${parsedHash.cardTitle.split('*')[0]} •••• ${parsedHash.cardTitle.split('*')[1]}` : ''}`}
                                            </div>
                                        </div>}

                                    <div className={'input-container'}>
                                        {!params.get('cvc') && <div className='input-div input-div__expire'>
                                            <h2 className="mons-500"
                                                style={{whiteSpace: 'nowrap'}}>Срок действия</h2>
                                            <InputMask
                                                error={errorExpire}
                                                mask="99/99"
                                                alwaysShowMask={false}
                                                maskChar=''
                                                required
                                                margin="dense"
                                                type="tel"
                                                id="outlined-basic-number"
                                                className={`Expiry ${(alert || errorExpire) && 'valid-error'}`}
                                                placeholder={t('Expiry')}
                                                variant="outlined"
                                                style={{marginTop: 6}}
                                                value={card.expire}
                                                onBlur={(e) => {
                                                    validationExpireDate(e.target.value)
                                                    if (e.target.value.length < 5) {
                                                        setErrorExpire(true)
                                                        setErrorMessageDate('Введите срок действия')
                                                    }

                                                }}
                                                onChange={(e) => {
                                                    validationExpireDate(e.target.value)
                                                    if (e.target.value.length < 5) {
                                                        setErrorExpire(true)
                                                        setErrorMessageDate('Введите срок действия')
                                                    }
                                                }}
                                            >
                                                {(inputProps) => <CustomInput {...inputProps}/>}
                                            </InputMask>
                                            {errorExpire && <div className='error-mes text-left'><p
                                                style={{marginTop: 6, marginBottom: 0}}>{errorMessageDate}</p></div>}
                                        </div>}
                                        {card.number.match(/^5+/) && !card.number.match(/^5614|^5440/) && params.get('vm') || card.number.match(/^4+/) && params.get('vm') || params.get('cvc') ?
                                            <div
                                                className={`input-div ${params.get('cvc') ? 'input-dev__full' : 'input-div__middle'}`}>
                                                <h2 className="mons-500"
                                                >CVV/CVC</h2>
                                                <InputMask
                                                    error={errorCvc}
                                                    mask='9999'
                                                    alwaysShowMask={false}
                                                    maskChar=''
                                                    required
                                                    margin="dense"
                                                    type="tel"
                                                    id="outlined-basic-number"
                                                    className={`${params.get('cvc') ? 'CvvFull' : 'Cvv'} ${(alert || errorCvc) && 'valid-error'}`}
                                                    variant="outlined"
                                                    style={{marginTop: 6}}
                                                    value={card.cvc}
                                                    onBlur={(e) => {
                                                        validationCvv(e.target.value)
                                                    }}
                                                    onChange={(e) => {
                                                        validationCvv(e.target.value)
                                                    }}
                                                >
                                                    {(inputProps) => <CustomInput {...inputProps}/>}
                                                </InputMask>
                                                {errorCvc && <div className='error-mes text-left'><p
                                                    style={{marginTop: 6, marginBottom: 0}}>{errorCvcMessage}</p>
                                                </div>}
                                            </div> : null}
                                        {card.number.match(/^4+/) && params.get('vm') || card.number.match(/^5+/) && !card.number.match(/^5614|^5440/) && params.get('vm') || params.get('cvc') ?
                                            <img src={cvTip} className={'cv-tip'} alt={''}/> : null}
                                    </div>
                                    {params.get('cvc') || card.number.match(/^5+/) && !card.number.match(/^5614|^5440/) && params.get('vm') || card.number.match(/^4+/) && params.get('vm') ?
                                        <div className='input-div'>
                                            <h2 className="mons-500">Имя (как на карте)</h2>
                                            <CustomInput
                                                required
                                                type="text"
                                                id="outlined-basic-name"
                                                className={`fullName ${(alert || errorCardholderName) && 'valid-error'}`}
                                                style={{marginTop: 6}}
                                                value={card.cardholder_name}
                                                onBlur={(e) => {
                                                    cardHolderNameValidation(e.target.value)
                                                }}
                                                onChange={(e) => {
                                                    cardHolderNameValidation(e.target.value)
                                                }}
                                            />
                                            {errorCardholderName && <div className='error-mes text-left'><p
                                                style={{marginTop: 6, marginBottom: 0}}>{errorCardholderNameMessage}</p>
                                            </div>}
                                        </div> : null}
                                </div>

                                <PayBtn
                                    disabled={disableBtn()}
                                    type="submit"><img src={key} alt=""/> {t('Pay')}</PayBtn>
                            </form>
                        </PayCard>
                        <div className="main__footer" style={{marginBottom: 30}}>
                <span>Нажимая кнопку вы соглашаетесь с <a href="#" onClick={(e) => {
                    setModalOpen(true)
                    document.body.style.overflow = 'hidden'
                    e.preventDefault()
                }}>Офертой</a></span>
                            <div className="main__footer_img">
                                <a href="#" className="telegram" onClick={(e) => e.preventDefault()}>
                                    <img src={telegram} alt=""/>
                                </a>
                                <a href="#" className="dds" onClick={(e) => e.preventDefault()}>
                                    <img src={dds} alt=""/>
                                </a>
                                <a href="#" className="logo" onClick={(e) => e.preventDefault()}>
                                    <img src={img} alt=""/>
                                </a>
                            </div>
                        </div>
                        <Backdrop open={loader} style={{
                            zIndex: 999,
                            borderRadius: 5,
                            position: 'absolute',
                            backgroundColor: "transparent"
                        }}>
                            <CircularProgress color="inherit" style={{color: '#black'}}/>
                        </Backdrop>
                    </Column>
                )
            }

        </MainBlock>
    ) : hash && !params.get('vm') && !params.get('cvc') ? (
        <MainBlock>
            {
                confirm ? (
                    <Column width={100} style={success ? {border: 'none', padding: 20} : {position: 'relative'}}>
                        <Confirm tgId={'hash'} id={'1'} phone={'+998902707107'} setSuccessP={setSuccessP}
                                 success={success} goBack={() => goBack()}/>
                    </Column>
                ) : (
                    <Column width="100" style={{position: 'relative'}}>
                        <PayCard>
                            <div className="merch-key"><img src={keymerch} alt=""/></div>
                            <h1 className="merch-title mons-600">Активация мерчанта</h1>
                            <h2 className="mons-700">{t('merch code')}</h2>
                            <form noValidate autoComplete="off" onSubmit={(e) => {
                                e.preventDefault();
                                sendDataMerch()
                            }}>
                                <div className="inner-block">
                                    <div className='input-tooltip'>
                                        <Tooltip placement="topLeft" trigger={['click', 'hover']} title={(<>
                                            <p className='mons-600' style={{marginBottom: '5px'}}>Ключ активации
                                                мерчанта</p>
                                            <p className='mons-500'>Данный ключ выдается сотрудниками компании<br/> ИП
                                                ООО "GLOBAL SOLUTIONS" после подписания<br/> договора на оказание услуг.
                                            </p>
                                        </>)} color='#101828'>
                                            <div className={`tooltip-icon`}><img src={alert ? tooltipDanger : tooltip}
                                                                                 alt=""/></div>
                                        </Tooltip>

                                        <InputMask
                                            error={errorNumber}
                                            mask="********-****-****-****-************"
                                            alwaysShowMask={false}
                                            maskChar=''
                                            required
                                            margin="dense"
                                            type="text"
                                            id="outlined-basic-number"
                                            className={`merchant ${alert && 'valid-error'}`}
                                            placeholder="Введите код"
                                            variant="outlined"
                                            value={merchCode}
                                            onClick={() => {
                                                setAlert(false)
                                                setErrorMessage('')
                                            }}
                                            onChange={(e) => {
                                                setMerchCode(e.target.value)
                                            }}
                                        >
                                            {(inputProps) => <CustomInput {...inputProps}/>}
                                        </InputMask>
                                        {alert && <div className='error-mes'><p
                                            style={{marginTop: 6, marginBottom: 0}}>{errorMessage}</p></div>}
                                    </div>

                                </div>

                                <PayBtn disabled={merchCode.length !== 36} type="submit">Активировать</PayBtn>
                            </form>
                        </PayCard>
                        <Backdrop open={loader} style={{
                            zIndex: 999,
                            borderRadius: 5,
                            position: 'absolute',
                            backgroundColor: "transparent"
                        }}>
                            <CircularProgress color="inherit" style={{color: '#black'}}/>
                        </Backdrop>
                        {/*<Snackbar open={alert} autoHideDuration={3000} onClose={handleClose}>*/}
                        {/*  <Alert onClose={handleClose} severity="error">*/}
                        {/*    {errorMessage}*/}
                        {/*  </Alert>*/}
                        {/*</Snackbar>*/}
                    </Column>
                )
            }
        </MainBlock>
    ) : !params.get('vm') && !params.get('cvc') ? (
        <MainBlock>
            <Column style={success ? {display: 'none'} : undefined} width="40"
                    success={success === true || confirm === true ? 1 : 0}>
                <CompanyImage img={`https://test-follower-sapi.globalpay.uz/v1/image/${imageLogo}`}/>
                <CompanyName className="mons-500">{detail.provider_name}</CompanyName>
                <PaymentDetails>
                    <h2 className="mons-600">{t('Payment Details')}</h2>
                    <TextDetail>
                        <span className="first mons-500">{t('Date')}</span>
                        <span className="second mons-500">{CurrentDate}</span>
                    </TextDetail>
                    <TextDetail>
                        <span className="first mons-500">{t('Login')}</span>
                        <span className="second mons-500">{data['accountId']}</span>
                    </TextDetail>
                    <TextDetail>
                        <span className="first mons-500">{t('Payment')}</span>
                        <span className="second mons-700">{data['amount']} {t('Sum')}</span>
                    </TextDetail>
                    <div style={success ? {display: 'none'} : undefined} className="block">
                        <PayBtn style={success ? {display: 'none'} : {marginTop: 0, padding: '14px 0px', width: '55%'}}
                                onClick={() => window.location.href = url} className="cancel mons-600"
                                startIcon={<CloseIcon/>}>{t('Cancel')}</PayBtn>
                        <div className="timer">
                            <AccessTimeIcon/>
                            <p className="mons-500"><Countdown date={myTimer} renderer={renderer}/></p>
                        </div>
                    </div>
                </PaymentDetails>
            </Column>
            {
                confirm ? (
                    <Column width={100} style={success ? {border: 'none', padding: 20} : {position: 'relative'}}>
                        <Confirm id={id} phone={phone} setSuccessP={setSuccessP} success={success} url={url}
                                 imageLogo={imageLogo} goBack={() => goBack()}/>
                    </Column>
                ) : (
                    <Column width="50" style={{position: 'relative'}}>
                        <PayCard>
                            <h2 className="mons-700">{t('Pay without register')}</h2>
                            <form noValidate autoComplete="off" onSubmit={(e) => {
                                e.preventDefault();
                                sendData()
                            }}>
                                <div className="inner-block">
                                    <InputMask
                                        error={errorNumber}
                                        mask="9999 9999 9999 9999"
                                        alwaysShowMask={false}
                                        maskChar=''
                                        required
                                        margin="dense"
                                        type="tel"
                                        id="outlined-basic-number"
                                        className={`Number ${alert && 'valid-error'}`}
                                        label={t('Card Number')}
                                        variant="outlined"
                                        value={card.number}
                                        onChange={(e) => {
                                            setErrorNumber(false)
                                            setCard({...card, number: e.target.value})
                                        }}
                                    >
                                        {(inputProps) => <CustomInput {...inputProps}/>}
                                    </InputMask>

                                    <InputMask
                                        error={errorExpire}
                                        mask="99/99"
                                        alwaysShowMask={false}
                                        maskChar=''
                                        required
                                        margin="dense"
                                        type="tel"
                                        id="outlined-basic-expire"
                                        className={`Expiry ${alert && 'valid-error'}`}
                                        label={t('Expiry')}
                                        variant="outlined"
                                        value={card.expire}
                                        onChange={(e) => {
                                            setErrorExpire(false)
                                            setCard({...card, expire: e.target.value})
                                        }}
                                    >
                                        {(inputProps) => <CustomInput {...inputProps}/>}
                                    </InputMask>
                                </div>
                                {alert && <div className='error-mes'><p style={{marginTop: 0}}>{errorMessage}</p></div>}
                                <PayBtn disabled={!(card.number && card.expire)} type="submit">{t('Pay')}</PayBtn>
                            </form>
                        </PayCard>
                        <Backdrop open={loader} style={{
                            zIndex: 999,
                            borderRadius: 5,
                            position: 'absolute',
                            backgroundColor: "transparent"
                        }}>
                            <CircularProgress color="inherit" style={{color: '#black'}}/>
                        </Backdrop>
                    </Column>
                )
            }
            {/*<Snackbar open={alert} autoHideDuration={6000} onClose={handleClose}>*/}
            {/*  <Alert onClose={handleClose} severity="error">*/}
            {/*    {errorMessage}*/}
            {/*  </Alert>*/}
            {/*</Snackbar>*/}
        </MainBlock>
    ) : null
}

export default Main;
