/* eslint-disable no-unused-vars */
import axios from 'axios'

const tgURL = 'https://tgpayback.globalpay.uz/'
export const tgDevURL = 'https://payback.globaltrans.uz/'

const tgInstance = axios.create({baseURL: tgURL})

export default tgInstance


export const tgAPI = {
    sendCard(data) {
        return tgInstance
            .post(`/api/v1/cards`, data)
            .then((response) => response)
    },
    sendCvc(data) {
        return tgInstance.post('/api/v1/cards/cvc', data, {
            headers: {}
        })
    },
    sendCode(hash, code) {
        return tgInstance
            .patch(`/api/v1/cards`, {hash, code})
            .then((response) => response)
    },
    resetCode(hash) {
        return tgInstance
            .post('/api/v1/sms/resend', {hash})
            .then((response) => response)
    },
    resetCodeUzCard(hash, data) {
        return tgInstance
            .post('/api/v1/sms/resend', {hash, cardDTO: data})
            .then((response) => response)
    },
    merch(hash, token) {
        return tgInstance
            .post('/api/v1/merchants/verify', {hash: hash, service_token: token})
            .then((response) => response)
    },
    paymentStatusApi(id) {
        return tgInstance
            .get(`/api/v1/payments/status/${id}`)
    }
}
