/* eslint-disable no-unused-vars */
import axios from 'axios'

const prodURL = 'https://follower-api.globalpay.uz/'
const stageURL = 'https://pre-follower-api.globalpay.uz/'
const devURL = 'https://test-follower-sapi.globalpay.uz/'

const instance = axios.create({baseURL: devURL})

export default instance

export const API = {
  tData(data) {
    return instance
      .post('/v1/acquiring/transaction', data)
      .then((response) => response)
  },
  tCard(id, data) {
    return instance
      .post(`/v1/acquiring/transaction/${id}/card`, data)
      .then((response) => response)
  },
  tCode(id, code) {
    return instance
    .post(`/v1/acquiring/transaction/${id}`, {
      code: code
    })
    .then((response) => response)
  },
  tReset(id, phoneNumber) {
    return instance
      .post(`/v1/acquiring/transaction/${id}/resend-code`, {
        notificationNumber: phoneNumber
      })
      .then((response) => response)
  },
  tCheck(id) {
    return instance
      .get(`v1/acquiring/transaction/download/${id}`, {
        responseType: 'blob'
      })
  }
}