import styled from 'styled-components'


export const FrameContainer = styled.div`
  overflow: hidden;
  width: 360px;
  height: 872px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 auto;

  .iframe {
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all .2s linear;
    transition: all .2s linear;
    border: none;
    background: transparent;
    border: 0px;
    margin-left: -450px!important;
    margin-top: 0;
    width: 810px!important;
    min-width: 810px!important;
    max-width: 810px!important;
    height: 1000px;
    display: block;
  }

  .iframe_show {
    visibility: visible;
    opacity: 1;
  }
`

export const LoaderContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  z-index: 500;
`
