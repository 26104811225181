import styled from 'styled-components'

export const Container = styled.div`
    background-color: #FFFFFF;
    margin-top: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 80px 0px; 
    @media (max-width: 576px) {
        padding: 0px 15px;
      margin-top: 18px;
    }
`;

export const Block = styled.div`
    width: 360px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
    background: #FFFFFF;
    border-radius: 5px;
    @media (max-width: 400px) {
      width: 100%;
    }
`