import styled from 'styled-components'

export const Container = styled.div`
    background-color: #FFFFFF;
    padding: 0px 140px;
    display: flex;
    align-items: center;
    height: 70px;
    @media(max-width: 576px) {
        padding: 0px 15px;
        justify-content: center;
      display: none;
    }
`;

export const Logo = styled.div`
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 121px;
    height: 40px;
`;