import {useTranslation} from "react-i18next";
import React, {useEffect} from "react";
import {Btn, BtnWrapper, InfoText, InfoTitle, InfoWrapper} from "./styles";


export default function ErrorPage() {
    const {t, i18n} = useTranslation()

    const changeLanguage = (code) => {
        if (code) {
            i18n.changeLanguage(code)
        }
    }

    useEffect(() => {
        i18n.changeLanguage('uz')
    }, [])
    const codes = [{
        code: 'uz',
        title: 'O‘z'
    },
        {
            code: 'ru',
            title: 'Ру'
        },
        {
            code: 'en',
            title: 'En'
        }]

    return (
        <InfoWrapper>
            <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="5" y="5" width="56" height="56" rx="28" fill="#FEE4E2"/>
                <path d="M40 26L26 40M26 26L40 40" stroke="#D92D20" strokeWidth="2" strokeLinecap="round"
                      strokeLinejoin="round"/>
                <rect x="5" y="5" width="56" height="56" rx="28" stroke="#FEF3F2" strokeWidth="10"/>
            </svg>

            <InfoTitle>
                {t('errorTitle')}
            </InfoTitle>
            <InfoText>
                {t('errorText')}
            </InfoText>
            <BtnWrapper>
                {codes.map(({
                                code,
                                title
                            }) => (
                    <Btn key={code} isActive={code === i18n.language} onClick={() => changeLanguage(code)}>
                        {title}
                    </Btn>
                ))}
            </BtnWrapper>
        </InfoWrapper>
    )
}