import React from 'react'
import {I18nextProvider, useTranslation} from 'react-i18next'
import ScrollToTop from './components/utils/ScrollToTop.jsx'
import i18n from './translates/i18n'
import "antd/dist/antd.css"

import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'

import Header from './components/Header/Header.js'
import Main from './components/Main/Main.js'

import styled from 'styled-components'
import Vm from "./components/Vm/Vm";
import SuccessPage from "./components/Result/success";
import ErrorPage from './components/Result/error'

const Container = styled.div`
  display: flex;
  height: calc(100vh - 70px);
  justify-content: center;
  align-items: center;

  h3 {
    text-align: center;
  }
`;

const NoMatchPage = () => {
    const {t} = useTranslation()
    return (
        <Container>
            <h3>{t('404')}</h3>
        </Container>
    )
}

const Error = (props) => {
    const {t} = useTranslation()
    return (
        <Container>
            <h3>{t('Error')}</h3>
        </Container>
    )
}

const App = () => {
    return (
        <I18nextProvider i18n={i18n}>
            <Router>
                <ScrollToTop>
                    <Header/>
                    <Switch>
                        <Route exact path="/" component={Main}/>
                        <Route exact path="/error" render={(props) => <Error {...props} />}/>
                        <Route exact path={'/vm-verification'} component={Vm}/>
                        <Route exact path={'/vm-success'} component={SuccessPage}/>
                        <Route exact path={'/vm-error'} component={ErrorPage}/>
                        <Route component={NoMatchPage}/>
                    </Switch>
                </ScrollToTop>
            </Router>
        </I18nextProvider>
    )
}

export default App