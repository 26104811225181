import styled from 'styled-components'


export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 48px;
  font-family: 'Inter', sans-serif;
`


export const InfoTitle = styled.h2`
  font-weight: 600;
  font-size: 24px;
  color: #101828;
  text-align: center;
  margin: 24px 0 8px;
`

export const InfoText = styled.p`
  color: #475467;
  margin-bottom: 32px;
  text-align: center;
`


export const BtnWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #F9FAFB;
  padding: 6px;
  border: 1px solid #F2F4F7;
  border-radius: 8px;
`

export const Btn = styled.button`
  width: 48px;
  height: 44px;
  border: none;
  font-weight: 600;
  text-align: center;
  font-size: 16px;
  font-family: 'Inter', sans-serif;
  cursor: pointer;
  /* Shadow/sm */
  border-radius: 6px;
  transition: all .2s linear;
  box-shadow: ${(props) => props.isActive ? '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);' : 'none'} ;
  background: ${(props) => props.isActive ? '#FFFFFF' : 'none'};
  &:first-child{
    margin: 0 6px;
  }
  &:last-child{
    margin: 0 6px;
  }
`