import styled from 'styled-components'
import {Modal} from 'antd'

import {Button, TextField} from '@material-ui/core/';

const documentHeight = () => {
    const doc = document.documentElement
    doc.style.setProperty('--doc-height', `${window.innerHeight - 100}px`)
}
window.addEventListener(`resize`, documentHeight)
documentHeight()
export const ModalInfo = styled(Modal)`

  .logo-modal {
    width: 32px;
    height: 32px;
    padding: 5px;
    border-radius: 8px;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.06),
    0px 1px 3px 0px rgba(16, 24, 40, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .ant-modal-content {
    padding: 20px 16px 0px 16px;
    border-radius: 10px;
    @media (max-width: 564px) {
      top: -20px;
    }


    .ant-modal-close-x {
      line-height: 67px;
    }

    .ant-modal-header {
      padding-top: 0;
      padding-left: 0;
      border-bottom: none;
    }

    .ant-modal-body {
      overflow-y: scroll;

      height: calc(var(--doc-height) - 100px);
      max-height: 100%;

      padding-left: 0px;

      ::-webkit-scrollbar {
        width: 4px; /* ширина для вертикального скролла */
        background-color: transparent;
      }


      ::-webkit-scrollbar-thumb {
        background-color: rgba(52, 64, 84, 0.15);
        border-radius: 7px;
        height: 152px;
      }


      h3 {
        font-size: 18px;
        font-weight: 600;
        color: #101828;
        padding-right: 15px;
      }

      .font-weight {
        font-size: 14px;
        font-weight: 600;
        color: #475467;
      }

      p {
        color: #475467;
        font-size: 14px;
      }
    }
  }
`


export const CompanyImage = styled.div`
  background-image: url('${props => props.img}');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 100%;
  height: 110px;
  @media (max-width: 576px) {
    width: 100%;
    margin-top: 20px;
  }
`

export const CompanyName = styled.h3`
  font-size: 16px;
  margin-top: 25px;
  margin-bottom: 20px;
  color: #727271;
`

export const PaymentDetails = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  padding: 20px 0px 0px;

  h2 {
    color: #727271;
    font-size: 16px;
    margin-top: 0px;
    margin-bottom: 25px;
  }

  .block {
    margin-top: 25px;
    display: flex;
    /* justify-content: flex-end; */
    justify-content: space-between;
    align-items: center;

    .timer {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .MuiSvgIcon-root {
        fill: #F2C94C;
        margin-right: 5px;
      }

      .mons-500 {
        color: #727271;
        font-size: 16px;
      }
    }
  }
`

export const TextDetail = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;

  .first {
    font-size: 16px;
    color: #8F8F8F;
  }

  .second {
    font-size: 16px;
    color: #434242;

    &.mons-700 {
      font-size: 22px;
    }
  }
`
export const PayCard = styled.div`
  padding-top: 20px;


  .merch-title {
    margin-top: 35px;
    text-align: center;
    @media (max-width: 450px) {
      font-size: 26px;
    }
  }

  h2 {
    color: #434242;
    font-size: 14px;
    margin: 0;
  }

  .merch-key {
    display: flex;
    justify-content: center;
  }


  form {

    .inner-block {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      position: relative;

      & > div {
        margin-bottom: 20px;
      }

      .input-container {
        display: flex;
        align-items: flex-start;
        position: relative;
      }

      .accepted-card {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-family: 'Inter', sans-serif;
      }

      .accepted-card__info {
        display: flex;
        align-items: center;
        font-family: 'Inter', sans-serif;
        font-weight: 500;
      }

      .accepted-card__info img {
        margin-right: 8px;
      }

      .input-div__middle {
        margin: 0 20px;
        flex: 0 0 auto;
        width: 150px;
      
        @media (max-width: 361px) {
          margin: 0 8px;
          width: 120px;
        }
      }

      .input-dev__full {
        flex: 0 0 auto;
        width: 80%;
        margin-right: 20px;
      }

      .input-div__expire {
        flex: 0 0 auto;
        width: 105px;
        @media (max-width: 361px) {
          width: 115px;
        }
      }

      .input-tooltip {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        position: relative;
      }

      .merchant {
        input {
          padding: 14.5px 34px 14.5px 14px !important;
        }
      }

      .cv-tip {
        padding-top: 42px;
      }

      .tooltip {
        position: absolute;
        z-index: 9999;
        right: 15px;
        top: 22px;
      }

      .card-img__absolute {
        position: absolute;
        top: 20px;
        right: 15px;
        display: flex;
        @media (max-width: 576px) {
          top: 19.5px;
        }

        & > div {
          width: 20px;
          height: 20px;

          img {
            width: 100%;
            height: 100%;
            @media (max-width: 576px) {
              vertical-align: inherit;
            }

          }

          &:first-child {
            margin-right: 5px;
          }
        }
      }

      .card-img {

        display: flex;
        @media (max-width: 576px) {

        }

        & > div {
          margin-right: 8px;

          img {
            width: 100%;
            height: 100%;
            @media (max-width: 576px) {
              vertical-align: inherit;
            }

          }

          &:last-child {
            margin-right: 8px;
          }
        }
      }


      .MuiOutlinedInput-notchedOutline {
        border-color: transparent !important;
      }
    }


    .Number {
      width: 100%;
    }

    .Merchant {
      width: 100%;
    }

    .Expiry {
      width: 105px;
      @media (max-width: 361px) {
        width: 115px;
      }
    }

    .Cvv {
      width: 150px;
      @media (max-width: 361px) {
        width: 100px;
      }
    }

    .CvvFull {
      width: 100%;
    }

    .fullName {
      width: 100%;
      text-decoration: none;
      border: 1px solid #D0D5DD;
      border-radius: 8px !important;
      input {
        text-transform: uppercase;
      }

      .MuiInput-underline:after {
        display: none;
      }
    }
  }
`

export const CustomInput = styled(TextField)`
  .MuiInputBase-root:before {
    display: none;
  }

  &&& {
    .MuiOutlinedInput-root {
      .MuiOutlinedInput-root:before {
        display: none;
      }

      input {
        border-radius: 8px !important;
        background: #FFFFFF;
        border: 1px solid #D0D5DD;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      }
    }

    .MuiInputLabel-outlined {
      transform: translate(14px, 16px) scale(1);
      @media (max-width: 576px) {
        transform: translate(14px, 14px) scale(1);
      }
    }

    .MuiInputLabel-shrink {
      transform: translate(14px, -6px) scale(0.75);
    }

    .Mui-focused {
      color: rgba(0, 0, 0, 0.87);
    }

    .Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: rgba(0, 0, 0, 0.87);
    }

    input {
      font-size: 18px;
      color: #303030;
      font-family: 'Montserrat', sans-serif;
      font-weight: 500;
      padding: 14.5px 14px;

      &:focus {
        background-clip: padding-box;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba(91, 154, 249, 0.1);
        border-radius: 8px;
        outline: #5B9AF9;
        border: 1px solid #5B9AF9;
      }

      @media (max-width: 576px) {
        font-size: 14px;
      }
    }
  }
`

export const PayBtn = styled(Button)`
  &&& {
    background: #E9532F;
    border: 1px solid #E9532F;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    padding: 14px 70px;
    margin-top: 5px;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    color: #ffffff;
    text-transform: capitalize;
    transition: background 300ms ease;
    line-height: 19.5px;
    width: 100%;
    border-radius: 8px !important;

    img {
      margin-right: 8px;
    }

    &:hover {
      background: #fc7554;
    }

    &.cancel {
      background: #ffffff;
      color: rgba(0, 0, 0, 0.87);
      border: 1px solid rgba(0, 0, 0, 0.15);

      &:hover {
        background: #ffffff;
      }
    }

    @media (max-width: 576px) {
      font-size: 16px;
      padding: 12px 0;
      margin: 0;
    }
  }
`

export const Column = styled.div`
  width: ${props => props.width}%;
  min-height: 380px;
  @media (max-width: 576px) {
    display: ${props => props.success ? 'none' : 'block'};
    width: 100% !important;
    border-right: 0;
    min-height: 210px;
  }

  .main__header {
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
      font-size: 30px;
      margin-top: 35px;
      font-weight: bold;
      color: #101828;
      @media (max-width: 576px) {
        font-size: 24px;
        margin-top: 15px;
      }
    }
  }

  .phone-text {
    display: flex;
    margin-bottom: 25px !important;
    justify-content: center;

    p {
      margin: 0 !important;
      font-size: 16px !important;
      color: #475467 !important;
    }

    p:first-child {
      margin-right: 8px;
    }

    p:last-child {

    }

    @media (min-width: 577px) {
      margin-bottom: 120px !important;
    }
  }

  .check-container {
    padding: 30px;

    .check-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 30px 0;
    }

    .mons-700 {
      text-align: center;
      font-size: 26px;
      margin-bottom: 20px;
    }

    .logo-header {
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      width: 200px;
      height: 60px;
    }

    .check-body {
      .divider {
        margin-top: 10px;
        margin-bottom: 10px;
        border-top: 1px solid rgba(0, 0, 0, 0.15);
        width: 100%;
      }

      .mons-500 {
        text-align: left;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;

        span:last-child {
          text-align: right;
        }
      }
    }
  }

  .main__footer {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 576px) {
      margin-top: 20px;
    }

    span {
      color: #475467;
      font-weight: 400;
      font-size: 14px;

      a {
        color: #E9532F;
        font-weight: 600;
        text-decoration: none;

      }
    }

    &_img {
      display: flex;
      margin-top: 32px;
      @media (max-width: 576px) {
        margin-top: 20px;
      }

      a {
        border: 1px solid rgba(114, 114, 113, 0.05);
        border-radius: 8px;
        padding: 10px;
        cursor: default;

        & img {
          width: 100%;
          height: 100%;
        }
      }

      & .telegram {
        width: 74px;
        margin-right: 16px;
      }

      & .dds {
        width: 87.2px;
        margin-right: 16px;
      }

      & .logo {
        width: 97.4px;
      }
    }

    &_logo {
      margin-top: 20px;

      a {
        width: 123px;
        height: 52px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 7px;
        background: #FFFFFF;
        box-shadow: 0px 1.5px 4.5px rgba(16, 24, 40, 0.1), 0px 1.5px 3px rgba(16, 24, 40, 0.06);
        border-radius: 12px;

        img {
          width: 107px;
          height: 33px;
          object-fit: cover;
        }
      }
    }
  }
`