import {notification} from "antd";
import React from "react";

export const closeWarningNotification = () => {
    const btn = (
        <div className={''} style={{marginTop: '12px'}}>
            Понятно
        </div>
    );
    const key = `open${Date.now()}`;
    notification['warning']({
        message: 'Это окно закроется автоматически после введения кода',
        description: '',
        closeIcon: btn,
        icon: <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.49984 13.3333V10M9.49984 6.66667H9.50817M17.8332 10C17.8332 14.6024 14.1022 18.3333 9.49984 18.3333C4.89746 18.3333 1.1665 14.6024 1.1665 10C1.1665 5.39763 4.89746 1.66667 9.49984 1.66667C14.1022 1.66667 17.8332 5.39763 17.8332 10Z"
                stroke="#DC6803" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>,
        key: key,
        duration: 5,
        placement: 'top'
    })
};