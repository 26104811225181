import React, {useEffect, useState} from 'react'
import FileDownload from 'js-file-download'
import {useTranslation} from 'react-i18next'
import {Backdrop, CircularProgress} from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'
import {API} from '../../../api'
import {tgAPI} from '../../../api/indextg'
import arrow from '../../../img/arrow.svg'

import {Column, PayBtn, PhoneText, Title} from './styles.js'
import otp from "../../../img/otp.svg";
import resend from "../../../img/resend.svg";
import success from "../../../img/success.svg";
import errorIcon from "../../../img/errorIcon.svg";
import atob from "atob";
import OtpInput from "../Otp/OtpInput";

// import {CompanyImage} from '../Main/styles'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Confirm = (props) => {
    const {t} = useTranslation();
    const [alert, setAlert] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [requestError, setRequestError] = useState(false)
    const [count, setCount] = useState(59);
    const [pin, setPin] = useState('');
    const [loader, setLoader] = useState(false);
    const [errorPin, setErrorPin] = useState(false);
    const [locationdData, setData] = useState({
        phone: '', id: ''
    })


    useEffect(() => {
        const inputs = Array.from(document.querySelectorAll('.input-otp input'))
        inputs.forEach(item => item.setAttribute('required', true))
    }, [])

    useEffect(() => {

        if (!props.tgId) {
            if (!props.id && !props.phone) {
                props.goBack()
            } else {
                setData({
                    phone: props.phone,
                    id: props.id
                })
            }
        }
    }, [props, props.id, props.phone]);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (count === 0) {
                clearTimeout(timer)
            } else {
                setCount(prevCount => prevCount - 1)
            }
        }, 1000);
        return () => clearTimeout(timer);
    });

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlert(false);
    };


    const resetCode = () => {

        setLoader(true)
        if (props.tgId && count === 0) {

            let decode = atob(props.tgId)
            const {cardToken} = JSON.parse(decode)
            if (cardToken) {

                const data = {
                    card_number: props.dataCard.number.split(' ').join(''),
                    exp_date: `${props.dataCard['expire'].split('/')[0]}/${props.dataCard['expire'].split('/')[1]}`,
                }
                tgAPI.sendCard(data)
                    .then(({status}) => {
                        if (status === 200) {
                            setCount(59)
                            setLoader(false)
                            setErrorMessage(null)
                            setErrorPin(null)
                            setPin('')
                        }
                    }).catch((e) => {
                    setErrorMessage(e?.response?.data?.error?.body?.message ? e?.response?.data?.error?.body?.message : `${t('Error')}`)
                    setAlert(true)
                    setLoader(false)
                })
            } else {
                setErrorMessage(t('Error'))
                setAlert(true)
                setLoader(false)
            }


        } else {
            if (count === 0) {
                API.tReset(locationdData.id, locationdData.phone)
                    .then(({status}) => {
                        if (status === 202) {
                            setCount(59)
                            setLoader(false)
                        }
                    })
                    .catch((e) => {

                        setErrorMessage(e?.response?.data?.error?.body?.message ? e?.response?.data?.error?.body?.message : `${t('Error')}`)
                        setAlert(true)
                        setLoader(false)
                    })
            }
        }
    }

    const sendData = () => {
        setLoader(true)

        if (props.tgId) {
            if (pin.length === 6) {
                tgAPI.sendCode(props.tgId, pin)
                    .then(({data}) => {
                        setLoader(false)
                        props.setSuccessP(true)
                        window.TelegramGameProxy.paymentFormSubmit({
                            credentials: {type: "card", token: data.result.token},
                            title: data.result.title
                        })
                    })
                    .catch((e) => {
                        setErrorPin(true)

                        if (e?.response?.status >= 500) {
                            setRequestError(true)
                        } else {

                            setErrorMessage(e?.response?.data?.error?.body?.message ? e?.response?.data?.error?.body?.message : `${t('error-mes')}`)
                        }

                        setLoader(false)
                    })
                    .finally(() => setLoader(false))
            }
            if (pin.length !== 6) {
                setErrorPin(true)
                setLoader(false)
            }
        } else {
            if (pin.length === 6) {
                API.tCode(locationdData.id, pin)
                    .then(({status}) => {
                        setLoader(false)
                        if (status === 202) {
                            props.setSuccessP(true)
                        }
                    })
                    .catch((e) => {
                        setErrorPin(true)
                        if (e?.response?.status >= 500) {
                            setRequestError(true)
                        } else {
                            setErrorMessage(e?.response?.data?.message ? e?.response?.data?.message : `${t('Error')}`)
                        }

                        setLoader(false)
                    })
                    .finally(() => setLoader(false))
            }
            if (pin.length !== 6) {
                setErrorPin(true)
                setLoader(false)
            }
        }
    }

    const setCheckPage = () => {
        API.tCheck(props.id)
            .then((response) => {
                FileDownload(response.data, 'check.pdf');
            });
    }

    const onChange = (value) => {
        setPin(value)
        setErrorPin(false)
    }


    if (!props.success && !requestError) {
        return (
            <>
                <div className="main__header">
                    <div>
                        <img src={otp} alt=""/>
                    </div>
                    <h1>Введите код</h1>
                </div>
                <div className="phone-text">
                    <PhoneText className="mons-500">{t('Phone text')}</PhoneText>
                </div>
                <form className="confirm-form form_position" onSubmit={(e) => {
                    e.preventDefault()
                    sendData()
                }}>
                   <div className={'confirm__wrapper'}>
                       <span className={`separator ${errorPin ? 'separator__error' : ''}`}>-</span>
                       <OtpInput value={pin} onChange={onChange} valueLength={6} error={errorPin}/>
                   </div>
                    {errorPin && props.tgId &&
                        <div className='error-mes' style={{marginTop: '6px'}}><p>{errorMessage}</p></div>}

                    <div className="buttons-row">
                        <PayBtn disabled={pin.length < 6} type="submit">{t('Pod')}</PayBtn>
                    </div>
                    {count === 0 ? (
                        <PhoneText className="btn resend" cursor={count} onClick={() => resetCode()}><img src={resend}
                                                                                                          alt=""/> {t('send new code')}
                        </PhoneText>) : <PhoneText className="btn"
                                                   cursor={count}>{t('code')} 00:{count < 10 ? `0${count}` : count}</PhoneText>}
                </form>
                <div style={{display: "flex", justifyContent: "center", marginTop: "32px"}}>
                    <Title className="mons-600" style={{display: "flex", alignItems: "center"}} onClick={() => {
                        props.goBack()
                    }}>
                        <img src={arrow} alt=""/>
                        {t('back')}
                    </Title>
                </div>
                <Backdrop open={loader}
                          style={{zIndex: 999, borderRadius: 5, position: 'absolute', backgroundColor: "transparent"}}>
                    <CircularProgress color="inherit" style={{color: '#black'}}/>
                </Backdrop>

            </>
        )
    } else if (requestError) {
        return (
            <Column>
                <div className="background-success">
                    <div className="success">
                        <div><img src={errorIcon} alt=""/></div>
                    </div>
                    <div className="background-error mons-700">Ошибка привязки карты</div>
                    <div className="background-span">Попробуйте добавить карту заново</div>
                    <div className="bottom-buttons">
                        <PayBtn className="close" type="submit" style={{width: "100%"}} onClick={() => {
                            props.goBack()
                        }}>Начать сначала</PayBtn>
                    </div>
                    {/*<div className="btn_back">*/}
                    {/*    <a href="#" onClick={(e)=>{*/}
                    {/*        e.preventDefault()*/}
                    {/*        if(process.env.NODE_ENV==='development'){*/}
                    {/*            window.location.href = 'https://t.me/Botman_dev_bot'*/}
                    {/*        }*/}
                    {/*        if(process.env.NODE_ENV==='production'){*/}
                    {/*            window.location.href = 'https://t.me/Botman_dev_bot'*/}
                    {/*        }*/}

                    {/*    }}>Закрыть</a>*/}
                    {/*</div>*/}
                </div>
            </Column>
        )
    } else if (window.outerWidth > 576) {
        return props.tgId === 'hash' ? (
            <Column>
                <div className="background-success">
                    <div className="success">
                        <div><img src={success} alt=""/></div>
                    </div>
                    <div className="background-text merch mons-700">{t('Successfully merch')}</div>
                    <div className="bottom-buttons">
                        <PayBtn className="close" type="submit" style={{width: "100%"}} onClick={() => {
                            window.location.href = 'https://t.me/botfather'
                        }}>{t('thanks')}</PayBtn>
                    </div>
                </div>
            </Column>
        ) : (
            <Column>
                <div className="background-success">
                    <div className="success">
                        <div><img src={success} alt=""/></div>
                    </div>
                    <div className="background-text mons-700">{t('Successfully payment')}</div>
                    <div className="bottom-buttons">
                        <PayBtn className="close" type="submit" onClick={() => {
                            window.location.href = props.tgId ? 'https://pay.global.uz/' : props.url
                        }}>{t('close')}</PayBtn>
                        {
                            props.tgId ? undefined : (<PayBtn className="download" type="submit"
                                                              onClick={() => setCheckPage()}>{t('download')}</PayBtn>)
                        }
                    </div>
                </div>
            </Column>
        )
    } else {
        return props.tgId === 'hash' ? (
            <Column>
                <div className="background-success">
                    <div className="success">
                        <div><img src={success} alt=""/></div>
                    </div>
                    <div className="background-text mons-700">{t('Successfully merch')}</div>
                    <div className="bottom-buttons">
                        <PayBtn className="close" type="submit" style={{width: "100%"}} onClick={() => {
                            window.location.href = 'https://t.me/botfather'
                        }}>{t('thanks')}</PayBtn>
                    </div>
                </div>
            </Column>
        ) : (
            <Column>
                <div className="background-success">
                    <div className="success">
                        <div><img src={success} alt=""/></div>
                    </div>
                    <div className="background-text mons-700">{t('Successfully payment')}</div>
                    <div className="bottom-buttons">
                        <PayBtn className="close" type="submit" onClick={() => {
                            window.location.href = props.tgId ? 'https://pay.global.uz/' : props.url
                        }}>{t('close')}</PayBtn>
                        {
                            props.tgId ? undefined : (<PayBtn className="download" type="submit"
                                                              onClick={() => setCheckPage()}>{t('download')}</PayBtn>)
                        }
                    </div>
                </div>
            </Column>
        )
    }
}

export default Confirm;
