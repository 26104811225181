import React, {useEffect} from "react";
import {Btn, BtnWrapper, InfoText, InfoTitle, InfoWrapper} from './styles'
import {useTranslation} from 'react-i18next'
import {FrameContainer} from "../Vm/styles";
import IFrame from "../Vm/components/iframe";


export default function SuccessPage() {
    const {t, i18n} = useTranslation()
    const params = new URLSearchParams(window.location.search)

    const changeLanguage = (code) => {
        if (code) {
            i18n.changeLanguage(code)
        }
    }

    useEffect(() => {
        i18n.changeLanguage('uz')
        if (params.get('hash')) {
            const timeout = setTimeout(() => {
                window.location.replace(`https://3574dd9fc7f2.ngrok.app/vm-success?hash=${params.get('hash')}`)
            }, 3000)

            return () => {
                clearTimeout(timeout)
            }
        }
    }, [])
    const codes = [{
        code: 'uz',
        title: 'O‘z'
    },
        {
            code: 'ru',
            title: 'Ру'
        },
        {
            code: 'en',
            title: 'En'
        }]




    return (
        <InfoWrapper>
            <svg width="67" height="66" viewBox="0 0 67 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="5.5" y="5" width="56" height="56" rx="28" fill="#FEF0C7"/>
                <path
                    d="M45.9833 32.4167L43.6506 34.75L41.3166 32.4167M43.9359 34.1667C43.9783 33.7836 44 33.3943 44 33C44 27.201 39.299 22.5 33.5 22.5C27.701 22.5 23 27.201 23 33C23 38.799 27.701 43.5 33.5 43.5C36.7985 43.5 39.7417 41.9791 41.6667 39.6002M33.5 27.1667V33L37 35.3333"
                    stroke="#DC6803" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <rect x="5.5" y="5" width="56" height="56" rx="28" stroke="#FFFAEB" strokeWidth="10"/>
            </svg>
            <InfoTitle>
                {t('successTitle')}
            </InfoTitle>
            <InfoText>
                {t('successText')}
            </InfoText>
            <BtnWrapper>
                {codes.map(({
                                code,
                                title
                            }) => (
                    <Btn key={code} isActive={code === i18n.language} onClick={() => changeLanguage(code)}>
                        {title}
                    </Btn>
                ))}
            </BtnWrapper>
        </InfoWrapper>
    )
}