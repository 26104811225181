import React, {useEffect} from "react";
import {closeWarningNotification} from "../../../utils/notifications";


export default function IFrame({loadedHandler, loaded, decodedHash}) {

    useEffect(() => {
        closeWarningNotification()
    }, [])

    return (
        <iframe width={810} title={'vm-verification'} onLoad={loadedHandler}
                className={`iframe ${loaded ? 'iframe_show' : ''}`} scrolling={'no'}
                src={`https://ecomm.kapital24.uz:6443/ecomm2/ClientHandler?trans_id=${decodedHash.transId.replace(/[+=/]$/, '%3D')}`}/>
    )
}