import React from 'react';
import {
    Container,
    Logo
} from './styles'

const Header = () => {
    return(
        <Container>
            <Logo className="logo-header"/>
        </Container>
    )
}

export default Header;