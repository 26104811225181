import styled, {keyframes} from 'styled-components'

import {Button, TextField} from '@material-ui/core/';

export const Title = styled.p`
  font-size: 16px;
  margin: 0;
  color: #434242;
  display: inline-block;
  cursor: pointer;
  transition: color 300ms ease;
  img {
    margin-right: 8px;
  }
  &:hover {
    color: #6d6d6d;
  }
  @media (max-width: 576px) {
    margin: 20px;
    float: right;
  }
`


export const PhoneText = styled.p`
  text-align: center;
  &.resend{
    color: #E9532F!important;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      margin-right: 8px;
    }
  }
  &.mons-600 {
    margin: 12px 0px 20px;
    color: #545454;
  }
  &.btn {
    cursor: ${props => props.cursor === 0 ? 'pointer' : 'no-drop'};
    text-align: center;
    margin: 20px 0px 0px;
    color: #434242;
    &:hover {
      text-decoration: ${props => props.cursor === 0 ? 'underline' : 'none'};
    }
  }
`

const Lights = keyframes`
  0% {
    transform: scale(0.3);
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
`
const Icon = keyframes`
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
`

export const Column = styled.div`
  width: 100%;
  .background-success {
    min-height: 380px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .success {
      margin-bottom: 20px;
    }
    .background-text {
      font-weight: 600;
      font-size: 30px;
      text-align: center;
      color: #101828;
      margin-bottom: 30px;
      @media(max-width: 576px) {
        font-size: 22px;
      }
      
      &.merch {
        font-size: 26px;
      }
    }
    .bottom-buttons {
      width: 100% !important;
    }
    .background-error {
      font-weight: 600;
      font-size: 30px;
      text-align: center;
      color: #101828;
      margin-bottom: 12px;
      @media(max-width: 576px) {
        font-size: 22px;
      }
    }
    .background-span {
      font-weight: 400;
      font-size: 16px;
      text-align: center;
      color: #475467;
      margin-bottom: 32px;
    }
    
    .btn_back {
      margin-top: 32px;
      a {
        text-decoration: none;
        font-weight: 600;
        font-size: 14px;
        color: #475467;
      }
    }
    
  }
  .background-success-mobile {
    min-height: 380px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    .background-icon {
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      width: 53px;
      height: 53px;
      position: relative;
      top: -70px;
      z-index: 20;
      animation: ${Icon} .5s ease 1;
    }
    .background-lights {
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      width: 116px;
      height: 63px;
      position: absolute;
      top: 20%;
      z-index: 10;
      opacity: 0;
      animation: ${Lights} .8s ease .5s 1;
    }
    .background-text {
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      position: absolute;
      bottom: 47%;
      z-index: 20;
      color: #545454;
      animation: ${Icon} .5s ease 1;
    }
  }

  @media (max-width: 576px) {
    &.mobile {
      display: none;
    }
    width: 100%;
  }
`
export const PayBtn = styled(Button)`
  &&& {
    background: #E9532F;
    border: 1px solid #E9532F;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    padding: 14px;
    border-radius: 8px!important;
    width: 100%;
    margin-top: 25px;
    color: #ffffff;
    font-weight: 500;
    font-size: 16px;
    text-transform: unset;
    line-height: 19.5px;
    transition: background 300ms ease;
    &:hover {
      background: #fc7554;
    }
    &.cancel {
      background: #ffffff;
      color: #000000;
      border: 1px solid #6c6c6c;
      &:hover {
        background: #e3e3e3;
      }
    }
    &.close {
      margin: 0;
    }
    &.download {
      margin-top: 12px;
      background: #ffffff;
      color: rgba(0,0,0,0.87);
      border: 1px solid rgba(0,0,0,0.15);
    }
    @media (max-width: 576px) {
      font-size: 16px;
      margin-top: 18px;
    }
  }
`
export const CustomInput = styled(TextField)`
  &&& {
    .MuiOutlinedInput-root {
      border: 1px solid #D0D5DD;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px!important;
    }
    .MuiInputLabel-outlined {
      transform: translate(14px, 16px) scale(1);
    }
    .MuiInputLabel-shrink {
      transform: translate(14px, -6px) scale(0.75);
    }
    .Mui-focused {
      color: rgba(0, 0, 0, 0.87);
    }
    .Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: rgba(0, 0, 0, 0.87);
    }
    input {
      font-weight: 500;
      font-size: 16px;
      color: #303030;
      padding: 14.5px 14px
    }
  }
` 