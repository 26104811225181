import React, {useEffect, useState} from "react";
import {FrameContainer, LoaderContainer} from './styles'
import {decode, encode} from 'js-base64';
import {isJsonString} from "../utils/json-parses";
import {Backdrop, CircularProgress} from "@material-ui/core";
import IFrame from "./components/iframe";


export default function Vm() {
    const [decodedHash, setDecodedHash] = useState({
        id: '',
        transId: ''
    })
    const [loaded, setLoaded] = useState(false)
    let params = new URLSearchParams(window.location.search);


    useEffect(() => {
        if (params.get('hash')) {
            let decodedHash = decode(encode(params.get('hash')))
            const parsedHash = isJsonString(decode(decodedHash)) ? JSON.parse(decode(decodedHash)) : undefined
            if (parsedHash && parsedHash.transId) {
                setDecodedHash(parsedHash)
            } else {
                window.location.href = 'https://global.uz/404'
            }
        } else {
            window.location.href = 'https://global.uz/404'
        }
    }, [])


    const loadedHandler = () => {
        setLoaded(true)
    }


    return (
        <FrameContainer>
            {!loaded && <LoaderContainer>
                <CircularProgress color="inherit" style={{color: '#black'}}/>
            </LoaderContainer>}
            {decodedHash && decodedHash.transId ?
                <IFrame decodedHash={decodedHash} loaded={loaded} loadedHandler={loadedHandler}/> : null}
        </FrameContainer>
    )
}