import React from 'react';
import {
    Container,
    Block
} from './styles.js';

const MainBlock = (props) => {
    return(
        <Container>
            <Block>
                {props.children}
            </Block>
        </Container>
    )
}

export default MainBlock;