import React, {useEffect, useMemo, useRef} from 'react';
import {RE_DIGIT} from "../../constants/regex";


const OtpInput = ({value, valueLength, onChange, error}) => {

    const ref = useRef(null)

    useEffect(() => {
        if (ref && ref.current){
            ref.current.focus()
        }
    }, [ref])
    const valueItems = useMemo(() => {
        const valueArray = value.split('');
        const items = [];
        for (let i = 0; i < valueLength; i++) {
            const char = valueArray[i];

            if (RE_DIGIT.test(char)) {
                items.push(char);
            } else {
                items.push('');
            }
        }

        return items;
    }, [value, valueLength]);


    const focusToNextInput = (target) => {
        const nextElementSibling =
            target.nextElementSibling

        if (nextElementSibling) {
            nextElementSibling.focus();
        }
    };
    const focusToPrevInput = (target) => {
        const previousElementSibling =
            target.previousElementSibling

        if (previousElementSibling) {
            previousElementSibling.focus();
        }
    };
    const inputOnChange = (
        e,
        idx
    ) => {


        const target = e.target;
        let targetValue = target.value.trim();
        const isTargetValueDigit = RE_DIGIT.test(targetValue);

        if (!isTargetValueDigit && targetValue !== '') {
            return;
        }

        if (!isTargetValueDigit && targetValue !== '') {
            return;
        }

        const nextInputEl = target.nextElementSibling

        // only delete digit if next input element has no value
        if (!isTargetValueDigit && nextInputEl && nextInputEl.value !== '') {
            return;
        }

        targetValue = isTargetValueDigit ? targetValue : ' ';
        targetValue = isTargetValueDigit ? targetValue : ' ';

        const targetValueLength = targetValue.length;

        if (targetValueLength === 1) {
            const newValue =
                value.substring(0, idx) + targetValue + value.substring(idx + 1);

            onChange(newValue);

            if (!isTargetValueDigit) {
                return;
            }

            const nextElementSibling =
                target.nextElementSibling

            if (nextElementSibling) {
                focusToNextInput(target)
            }
        } else if (targetValueLength === valueLength) {
            onChange(targetValue);

            target.blur();
        }
    };

    const inputOnKeyDown = (e) => {
        const {key} = e;
        const target = e.target

        if (key === 'ArrowRight' || key === 'ArrowDown') {
            e.preventDefault();
            return focusToNextInput(target);
        }

        if (key === 'ArrowLeft' || key === 'ArrowUp') {
            e.preventDefault();
            return focusToPrevInput(target);
        }

        const targetValue = target.value


        if (e.key !== 'Backspace' || targetValue !== '') {
            return;
        }

        focusToPrevInput(target);
    };

    const inputOnFocus = (e) => {
        const { target } = e;

        // keep focusing back until previous input
        // element has value
        const prevInputEl =
            target.previousElementSibling

        if (prevInputEl && prevInputEl.value === '') {
            return prevInputEl.focus();
        }

        target.setSelectionRange(0, target.value.length);
    };

    return (
        <div className={'confirm-inputs'}>
            {valueItems.map((digit, idx) => (
                   <input
                       ref={idx === 0? ref : undefined}
                       key={idx}
                       type="text"
                       inputMode="numeric"
                       autoComplete="one-time-code"
                       pattern="\d{1}"
                       onChange={(e) => inputOnChange(e, idx)}
                       maxLength={valueLength}
                       onKeyDown={inputOnKeyDown}
                       onFocus={inputOnFocus}
                       autoFocus={idx === 0}
                       className={`input-otp ${error ? 'valid-errors-otp' : ''}`}
                       value={digit}
                   />
            ))}
        </div>
    );
};

export default OtpInput;